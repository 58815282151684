import Head from 'next/head';

import { addPathPrefix } from '@tb-core/components/composites/responsive-image';
import { ResponsiveImageProps } from '@tb-core/components/composites/responsive-image';

export const PreloadImage = ({ src, srcset }: ResponsiveImageProps) => (
    <Head>
        {src && (
            <link
                rel="preload"
                href={addPathPrefix(src)}
                as="image"
                media="(min-width: 768px)"
            />
        )}
        {srcset && (
            <link
                rel="preload"
                href={addPathPrefix(srcset)}
                as="image"
                media="(max-width: 767px)"
            />
        )}
    </Head>
);
