import dynamic from 'next/dynamic';
import { ComponentType } from 'react';

import { MenuSvgTilesProps } from '@tb-core/components/products/styled/menu-svg-tiles';
import ItemList from '@tb-core/components/simple/item-list';
import StandardHero from '@tb-core/components/styled/banners/standard-hero';
import { showModuleByUserStatus } from '@tb-core/components/styled/modules/helpers/show-module-by-user';
import { isDayPartNow } from '@tb-core/helpers/daypart';
import usePageContext from '@tb-core/hooks/use-page-context';
import useUserContext from '@tb-core/hooks/use-user-context';
import {
    GenericContentProps,
    GenericContentType,
    GenericModuleProps
} from '@tb-core/types';

const LoyaltyVotingCarousel = dynamic(() =>
    import('@tb-core/components/styled/modules/loyalty-voting-results')
);
const StandardBanner = dynamic(() =>
    import('@tb-core/components/styled/modules/standard-banner')
);
const StandardBucket = dynamic(() =>
    import('@tb-core/components/styled/modules/standard-bucket')
);
const StandardCarousel = dynamic(() =>
    import('@tb-core/components/styled/modules/standard-carousel')
);
const StandardFaq = dynamic(() =>
    import('@tb-core/components/styled/modules/standard-faq')
);
const StandardText = dynamic(() =>
    import('@tb-core/components/styled/modules/standard-text')
);
const StandardTile = dynamic(() =>
    import('@tb-core/components/styled/modules/standard-tile')
);
const StandardVideo = dynamic(() =>
    import('@tb-core/components/styled/modules/standard-video/standard-video')
);
const TileReversed = dynamic(() =>
    import('@tb-core/components/styled/modules/tile-reversed')
);

export type ContentModule =
    | ComponentType<GenericContentProps>
    | ComponentType<GenericModuleProps>
    | ComponentType<MenuSvgTilesProps>;

export type ContentModules = Record<
    string,
    ContentModule | ComponentType<GenericModuleProps>
>;

export interface ContentModuleListProps {
    includeHero?: boolean;
    modules: ContentModules;
}

/**
 * Modules are mapped from Contentful WebpageContent field "Type"
 * to the corresponding component. If more types are added in Contentful
 * add the type and component here.
 */
export const contentModuleLookup: ContentModules = {
    banner: StandardBanner,
    bucket: StandardBucket,
    carousel: StandardCarousel,
    faq: StandardFaq,
    hero: StandardHero,
    text: StandardText,
    tile: StandardTile,
    tilereversed: TileReversed,
    video: StandardVideo,
    votingcarousel: LoyaltyVotingCarousel
};

const ContentModuleList = ({
    includeHero = true,
    modules
}: ContentModuleListProps) => {
    const {
        colorTheme,
        contentGroupCollection: { items },
        topicMeta: { dayparts: daypartsConfig }
    } = usePageContext().content.pageData;
    const { isLoggedIn } = useUserContext();

    const { collapseFaq } =
        usePageContext().content.pageData.topicMeta.generic || {};

    const filterModuleList = (item: GenericContentProps) => {
        const isDaypartActive =
            !item.daypart || isDayPartNow(item.daypart, daypartsConfig);
        const showModuleToUser = showModuleByUserStatus(item, isLoggedIn);

        return (
            isDaypartActive &&
            showModuleToUser &&
            (includeHero || item?.type?.toLowerCase() !== 'hero')
        );
    };

    const moduleItems = items.filter(filterModuleList);

    const contentComponent = (item: GenericContentProps, i: number) => {
        const type = item.type || 'fallback';
        const Component = modules[type.toLowerCase() as GenericContentType];
        // if item does not have colorTheme, inherit Page colorTheme
        if (colorTheme && item.colorTheme === null) {
            item.colorTheme = colorTheme;
        }

        if (collapseFaq) {
            item.isCollapsible = true;
        }

        return Component && <Component key={i} {...item} />;
    };

    return <ItemList item={contentComponent} itemProps={moduleItems} />;
};

export default ContentModuleList;
