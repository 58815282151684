import classNames from 'classnames';
import dynamic from 'next/dynamic';

import Hero from '@tb-core/components/container/banners/hero';
import StandardHeroContent from '@tb-core/components/styled/banners/standard-hero/standard-hero-content';
import { getThemeFont } from '@tb-core/components/styled/modules/helpers/get-theme-fonts';
import { GenericModuleProps } from '@tb-core/types';

const StandardHeroFooter = dynamic(() =>
    import('@tb-core/components/styled/standard-hero-footer')
);

import styles from './styles.module.scss';

const StandardHero = ({
    backgroundImage,
    className,
    colorTheme,
    heroSize,
    style,
    ...props
}: GenericModuleProps) => {
    const theme = {
        backgroundColor: colorTheme?.containerBackground,
        ...style
    };

    const themeFontClassName = getThemeFont(colorTheme?.font);

    return (
        <Hero
            backgroundImage={backgroundImage}
            body={<StandardHeroContent {...{ ...props, colorTheme }} />}
            className={classNames(
                styles['standard-hero'],
                {
                    [styles[`standard-hero--${heroSize}`]]: heroSize !== null
                },
                themeFontClassName,
                className
            )}
            footer={
                (props.footnote || props.footnoteExcerpt) && (
                    <StandardHeroFooter {...{ ...props, colorTheme }} />
                )
            }
            style={theme}
        />
    );
};

export default StandardHero;
