import dynamic from 'next/dynamic';
import { ReactNode } from 'react';

import Banner, { BannerProps } from '@tb-core/components/composites/banner';
import { PreloadImage } from '@tb-core/components/container/banners/hero/preload-image';
import WebpageContent from '@tb-core/components/container/layout/webpage-content';
import { ResponsiveMediaProps } from '@tb-core/components/simple/responsive-media';

const ResponsiveMedia = dynamic(() =>
    import('@tb-core/components/simple/responsive-media')
);

export interface HeroProps extends BannerProps {
    backgroundImage?: ResponsiveMediaProps;
    body?: ReactNode;
    footer?: ReactNode;
}

const Hero = ({ backgroundImage, body, footer, ...bannerProps }: HeroProps) => (
    <>
        {backgroundImage && 'src' in backgroundImage && (
            <PreloadImage {...backgroundImage} />
        )}
        {(backgroundImage || body) && (
            <Banner {...bannerProps}>
                <WebpageContent
                    backgroundImage={
                        backgroundImage && (
                            <ResponsiveMedia
                                mobileContain
                                {...('url' in backgroundImage && {
                                    preload: 'auto'
                                })}
                                {...backgroundImage}
                            />
                        )
                    }
                    body={body}
                    footer={footer}
                />
            </Banner>
        )}
    </>
);

export default Hero;
