import { ContentfulMetaDataUserTags } from '@tb-core/constants/contentful';
import { GenericContentProps, PromoContentProps } from '@tb-core/types';

/**
 * Filter function for displaying modules based on user logged in status
 * @param { PromoContentProps | GenericContentProps } module the WebpageContent module
 * @param {boolean} isLoggedIn returned from useUserContext()
 * @returns boolean
 */
export const showModuleByUserStatus = (
    { contentfulMetadata }: PromoContentProps | GenericContentProps,
    isLoggedIn: boolean = false
) => {
    const arr = isLoggedIn
        ? [
              ContentfulMetaDataUserTags.USER_ALL,
              ContentfulMetaDataUserTags.USER_LOGGED_IN
          ]
        : [
              ContentfulMetaDataUserTags.USER_ALL,
              ContentfulMetaDataUserTags.USER_GUEST
          ];

    return contentfulMetadata?.tags.length > 0
        ? contentfulMetadata.tags.some(
              ({ id }: { id: ContentfulMetaDataUserTags }) => arr.includes(id)
          )
        : true;
};

export const filterHeroModuleByUserStatus = (isLoggedIn: boolean = false) => (
    module: PromoContentProps
) => {
    if (module?.type?.toLowerCase() !== 'hero') {
        return false;
    }

    return showModuleByUserStatus(module, isLoggedIn);
};
