import classNames from 'classnames';

import ResponsiveImage from '@tb-core/components/composites/responsive-image';
import FlexGridLayout from '@tb-core/components/layout/flex-grid-layout';
import PageTitle from '@tb-core/components/simple/page-title';
import ContentfulRichTextWithTheme, {
    renderRichTextMarks as renderText,
    tokenStrings
} from '@tb-core/components/styled/contentful-rich-text-with-theme';
import BrandedCtaGroup from '@tb-core/components/styled/modules/branded-cta-group';
import { ctaGroupAdapter } from '@tb-core/components/styled/modules/helpers';
import { entriesByTypename } from '@tb-core/helpers/content';
import { interpolate } from '@tb-core/helpers/interpolate';
import usePageContext from '@tb-core/hooks/use-page-context';
import { WebpageContentProps } from '@tb-core/types';

import styles from './styles.module.scss';

const StandardHeroContent = ({
    colorTheme,
    description,
    entriesCollection: { items },
    entryTitle,
    horizontalPosition = 'Left',
    title,
    type,
    wordmark
}: WebpageContentProps) => {
    const { seoTitle } = usePageContext().content.pageData;
    const { Link } = entriesByTypename(items);
    const ctaLimit = 2;
    const dataLayerTemplate = {
        'Analytics-Action': type,
        'Analytics-Value': title,
        entry_title: entryTitle,
        page_title: seoTitle
    };

    const ctaGroup = ctaGroupAdapter(colorTheme, dataLayerTemplate, Link);
    const theme = {
        color: colorTheme?.containerText
    };
    const titleTheme = {
        color: colorTheme?.titleText
    };
    const hasGraphicCta =
        ctaGroup && ctaGroup.some(cta => cta.graphic !== null);

    const displayTitle = title && interpolate(title, tokenStrings);

    return (
        <>
            <FlexGridLayout
                align="stretch"
                className={classNames(
                    styles.content,
                    horizontalPosition &&
                        styles[horizontalPosition.toLowerCase()]
                )}
                direction="col"
                style={theme}
            >
                <PageTitle style={titleTheme}>
                    <ResponsiveImage {...wordmark} />
                    {displayTitle && (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: displayTitle
                            }}
                        />
                    )}
                </PageTitle>
                {/* Description Copy */}

                {description?.json && (
                    <div>
                        <ContentfulRichTextWithTheme
                            colorTheme={colorTheme}
                            node={description.json}
                            renderOptions={{ renderText }}
                        />
                    </div>
                )}
                {/* CTA Group */}

                {ctaGroup && (
                    <FlexGridLayout
                        align="stretch"
                        className={classNames(styles['standard-hero-nav'], {
                            [styles.center]: horizontalPosition === 'Center',
                            [styles['nav-graphic']]: hasGraphicCta,
                            [styles.single]: ctaGroup.length < 2
                        })}
                        justify="center"
                    >
                        <BrandedCtaGroup
                            ctaGroup={ctaGroup.slice(0, ctaLimit)}
                        />
                    </FlexGridLayout>
                )}
            </FlexGridLayout>
        </>
    );
};

export default StandardHeroContent;
